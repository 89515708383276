<template>
  <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
    <div style="background-color: white">
      <div class="box">
        <el-row class="row">
          <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">预警时间</span></template>
                <span class="content">{{this.ruleForm.createTime}}</span>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">设备编号</span></template>
                <span class="content">{{this.ruleForm.deviceId}}</span>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">设备名称</span></template>
                <span class="content">{{this.ruleForm.deviceName}}</span>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">设备型号</span></template>
                <span class="content">{{this.ruleForm.deviceModel}}</span>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">绑定用户</span></template>
                <span class="content">{{this.ruleForm.userName}}</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">预警类型</span></template>
                <span class="content">{{this.ruleForm.warningTypeDescribe}}</span>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">预警内容</span></template>
                <span class="content">{{this.ruleForm.describe}}</span>
              </el-form-item>
          </el-col>
        </el-row>
       <el-row class="row" v-show="addressShow">
          <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">预警位置</span></template>
                <span class="content">{{this.ruleForm.address}}</span>
              </el-form-item>
          </el-col>
        </el-row>
      </div>
    </div>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      addressShow:true,
      ruleForm: {
      },
      rules: {

      },
    };
  },
  methods: {
    getParentData(data) {
      this.ruleForm = data;
      this.ruleForm.createTime = this.ruleForm.createTime.substring(0,16)
      if(data.warningType == 202) {
        this.addressShow = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 50%;
}
hr {
  border-color: #f4f4f526;
}
.title {
  // margin-right: 20px;
  // font-size: 18px;
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.box {
  width: 75%;
}

.row {
  margin: 20px 0px 20px 0px;
}

</style>
