<template>
  <div>
    <el-dialog v-model="dialogVisible" title="预警处理" width="30%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm" >
        <el-form-item prop="dealName">
          <template #label><span class="title">处&ensp;理&ensp;人：</span></template>
          <el-input v-model="ruleForm.dealName" placeholder="请输入处理人" class="input"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label><span class="title">{{this.describeTitle}}：</span></template>
          <el-input v-model="ruleForm.dealDescribe" placeholder="请输入对应内容" class="input"></el-input>
        </el-form-item>
        <el-form-item>
          
          <el-button type="primary" style="float: right" size="small" @click="submitForm('ruleForm')">确定</el-button>
          <el-button type="primary" style="float: right;margin-right:10px;" size="small" @click="cancel" plain>取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  operateDeviceWarning
} from '../../../api/api'
export default {
  data() {
    return {
      dialogVisible: true,
      describeTitle:'',
      ruleForm: {
        uuid:"",
        dealName: "",
        dealDescribe:"",
        dealFlag:"",
      },
      rules: {
        dealName:[
          {required: true,message: "请输入处理人员名称", trigger: "blur"},
        ],
        // dealDescribe:[
        //   {required: true,message: "请输入设备号", trigger: "blur"},
        // ],
      },
    }
  },
  methods: {
    getParentData(data,type) {
      this.ruleForm.uuid = data.uuid
      this.ruleForm.dealFlag = type

      if(1 == type) {
        this.describeTitle = '处理内容'
      }else if(0 == type) {
        this.describeTitle = '忽略原因'
      }
    },
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.operateDeviceWarning();
        } else {
          return false;
        }
      });
    },
    operateDeviceWarning() {
      operateDeviceWarning(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: '处理成功'});
          this.cancel();
          this.update();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    cancel() {
      this.$emit("cancel");
    },
    update() {
      this.$emit('update')
    },
  },
}
</script>

<style> 

</style>