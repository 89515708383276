<template>
  <div class="page">
    <ls-title-header @back="cancel" title="预警详情"></ls-title-header>
    <div class="box">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="基本资料" name="first">
          <DeviceWarningBaseInfo ref="baseView" @cancel="cancel"></DeviceWarningBaseInfo>
        </el-tab-pane>
        <el-tab-pane label="预警通知" name="second">
          <DeviceWarningNotifyInfo ref="noticeView" @cancel="cancel"></DeviceWarningNotifyInfo>
        </el-tab-pane>
        <el-tab-pane label="预警处理" name="third">
          <DeviceWarningDealInfo ref="dealView" @cancel="cancel"></DeviceWarningDealInfo>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import DeviceWarningBaseInfo from "./DeviceWarningBaseInfo.vue"
import DeviceWarningDealInfo from './DeviceWarningDealInfo.vue'
import DeviceWarningNotifyInfo from './DeviceWarningNotifyInfo.vue'
export default {
  components: {
    DeviceWarningBaseInfo,
    DeviceWarningDealInfo,
    DeviceWarningNotifyInfo
  },
  data() {
    return {
      warningData:null,
      activeName: "first",
    };
  },
  methods: {
    getParentData(data) {
      this.warningData = data;
      setTimeout(() => {
        this.$refs.baseView.getParentData(data);
      }, 200);
    },
    cancel() {
      this.$emit("cancel");
    },
    handleClick(tab) {
      if(0 == tab.index) {
        setTimeout(() => {
          this.$refs.baseView.getParentData(this.warningData);
        }, 200);
      } else if(1 == tab.index) {
        setTimeout(() => {
          this.$refs.noticeView.getParentData(this.warningData);
        }, 200);
      }else if(2 == tab.index) {
        setTimeout(() => {
          this.$refs.dealView.getParentData(this.warningData);
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
/* .page {
  padding: 40px 20px 40px 20px;
  background-color: white;
  border-radius: 4px;
} */
.page {
  padding: 0px 20px;
}
.box {
  padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
</style>
<style>
.el-tabs__item {
    font-size: 18px;
}

.el-tabs__nav-wrap::after {
  position: static !important;
}
</style>