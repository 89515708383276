<template>
  <div>
    <el-table :data="dataList" border style="width: 100%; border-radius: 10px" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText"> 
      <el-table-column prop="createTime" width="140" label="通知时间" label-class-name="tablehead"  align="center">
        <template #default="scope">
          <span>{{scope.row.createTime.substring(0,16)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="noticeType" width="80" label="通知方式" label-class-name="tablehead" align="center">
        <template #default="scope">
          <span v-if="scope.row.noticeType == 1">平台</span>
          <span v-if="scope.row.noticeType == 2">短信</span>
          <span v-if="scope.row.noticeType == 3">语音</span>
        </template>
      </el-table-column>
      <el-table-column prop="noticeName" width="120" label="通知人" label-class-name="tablehead" align="center"/>  
      <el-table-column prop="noticePhone"  width="140" label="通知电话"  label-class-name="tablehead" align="center"/>
      <el-table-column prop="describe" label="通知内容" label-class-name="tablehead" align="center"/>
      <el-table-column prop="resultType" width="80"  label="通知结果" label-class-name="tablehead" align="center">
        <template #default="scope">
          <span v-if="scope.row.resultType == 0" style="color:red">未送达</span>
          <span v-if="scope.row.resultType == 1" style="color:orange">等待接听</span>
          <span v-if="scope.row.resultType == 2" style="color:green">已送达</span>
          <span v-if="scope.row.resultType == 3" style="color:red">用户拒接</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div>
  </div>
 
</template>

<script>
import {
  getDeviceWarningNoticeInfos
} from '../../../api/api'
export default {
  data() {
    return {
      emptyText:'加载中',
      currentPage: 1,
      size: 10,
      total: 0, //总数
      dataList: [],
      warningData:null,
    };
  },
  mounted() {

  },
  methods: {
    getParentData(data) {
      this.warningData = data;
      this.getDeviceWarningNoticeInfos();
    },

    getDeviceWarningNoticeInfos() {
      let param = {
        uuid:this.warningData.uuid,
        page:this.currentPage - 1,
        size:this.size,
      }
      getDeviceWarningNoticeInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getDeviceWarningNoticeInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDeviceWarningNoticeInfos();
    },
  },
};
</script>

<style scoped>
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
</style>